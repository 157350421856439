const lng = document.querySelector(".locals")
const burgerBtn = document.querySelector('.burger-btn')
const EXPAND_CLASS_NAME = 'expand'

document.addEventListener(('click'), (e) => {
  if (lng) {
    if (e.target.dataset.role === "lng-switch") {
      lng.classList.toggle(EXPAND_CLASS_NAME)
    } else {
      lng.classList.remove(EXPAND_CLASS_NAME)
    }
  }
  if (burgerBtn) {
    if (e.target.dataset.role === 'mobile-nav-bg') {
      return
    }
    if (e.target.dataset.role === "burger-btn") {
      burgerBtn.classList.toggle(EXPAND_CLASS_NAME)
    } else {
      burgerBtn.classList.remove(EXPAND_CLASS_NAME)
    }
  }
})
